import React from 'react'
import Placeholder from './Placeholder'
import Cover from './pages/home/cover'
import Services from './pages/home/services'
import HowitWorks from './pages/home/how-it-works'
import Section from './section'
import About from './pages/about/about'
import SectionTop from './pages/public-charge/SectionTop'
import Benefits from './pages/benefits/benefits'
import BenefitsTab from './pages/benefits/benefitsTab'
import EligbilityStatus from './pages/benefits/eligibilityStatus'
import FactsToKnow from './pages/public-charge/FactsToKnow'
import FrequentlyAskedQuestions from './pages/public-charge/FAQ'
import SectionPublicBenefitsTop from './pages/public-benefits/SectionPublicBenefits'
import SectionPublicBenefitsTabs from './pages/public-benefits/SectionPublicBenefitsTabs'
import ResourceHeading from './pages/resources/resourcesHeading'
import EventsList from './pages/resources/eventList'
import ResourcesList from './pages/resources/resourcesList'

const Components = {
  'Cover' : Cover,
  'Services' : Services,
  'Section' : Section,
  'HowitWorks': HowitWorks,
  'about-component' : About,
  'SectionTop' : SectionTop,
  'FactsToKnow' : FactsToKnow,
  'FrequentlyAskedQuestions' : FrequentlyAskedQuestions,
  'SectionPublicBenefitsTop' : SectionPublicBenefitsTop,
  'SectionPublicBenefitsTabs' : SectionPublicBenefitsTabs,
  'ResourceHeading' : ResourceHeading,
  'EventsList' : EventsList,
  'ResourcesList' : ResourcesList,
  'Benefits': Benefits,
  'Benefits Tab List': BenefitsTab,
  'Eligibility Status': EligbilityStatus
}

const DynamicComponent = ({blok, lang}) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} lang={lang}/>
  }
 
  return  blok.component ? <Placeholder componentName={blok.component}/> : null
}

export default DynamicComponent
