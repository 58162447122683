import React from 'react'
// import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const EligbilityStatus = ({ blok }) => {

    console.log(blok)
    const getAccordion = blok.dropdown_option.map((faq, i) =>
        <AccordionItem key={i} className="mt-4">
            <AccordionItemHeading>
                <AccordionItemButton
                    className="accordion__button notification has-text-white is-size-5"
                    style={{ backgroundColor: "#30574a" }}
                >
                    {faq.name}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <div className="notification content has-background-white has-text-black">
                    {render(faq.description)}
                </div>
            </AccordionItemPanel>
        </AccordionItem>
    )

    return (
        <div {...sbEditable(blok)}>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}>
                <div className="container">
                    <h1
                        className="is-size-3 label has-text-centered"
                    >
                        {blok.title}
                    </h1>
                    <Accordion>
                        {getAccordion}
                    </Accordion>
                </div>
            </div>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}></div>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}></div>
        </div>
    )
}


//     const eligibilityList = blok.dropdown_option
//     // console.log(eligibilityList)

//     const [listDisplay, setList] = useState()
//     const [dropdown, setDropdown] = useState(0)

//     function scrollTo() {
//         const section = "eligiblityComponent"
//         console.log("test")
//         document.getElementById(section).scrollIntoView({ behavior: 'instant' })
//         document.getElementById(section).focus({ preventScroll: true })
//     }

//     const dropdownList = eligibilityList.map((item, i) =>
//         <div
//             className="dropdown-item"
//             key={i}
//             onClick={(e) => { setDropdown(0); setList(i);}}
//         >{item.name}</div>
//     )

//     const selectEligibilityDisplay = eligibilityList.map((item, i) =>
//         <div
//             className="notification content has-background-white"
//             value={i}
//             key={i}
//             style={listDisplay == i ? { display: 'block' } : { display: 'none' }}
//         >
//             {render(item.description)}
//         </div>
//     )

//     return (
//         <div {...sbEditable(blok)} ref={inputRef}>
//             <div className="section" id="eligiblityComponent" style={{ backgroundColor: "#FFF7EC" }}>
//                 <div className="container notification has-background-white">
//                     <div className="columns is-vcentered is-centered">
//                         <div className="column is-full is-size-5">
//                             <h3 className='title'>What are my benefits?</h3>
//                             <p
//                                 className='pb-4'
//                             >
//                                 Click here for your benefits based on your eligibility</p>
//                             <div
//                                 className={`dropdown ${dropdown == 1 ? "is-active" : ""}`}
//                                 style={{ width: '100%' }}
//                             >
//                                 <div className="dropdown-trigger"
//                                     style={{ width: '100%' }}
//                                     onClick={() => { setDropdown(1) }}
//                                 >
//                                     <button className="button" aria-haspopup="true"
//                                         style={{ width: '100%' }}
//                                         aria-controls="dropdown-menu">
//                                         <span>Select your eligibility</span>
//                                         <span className="icon is-small">
//                                             <i className="fas fa-angle-down" aria-hidden="true"></i>
//                                         </span>
//                                     </button>
//                                 </div>
//                                 <div className="dropdown-menu mt-0 pt-0" id="dropdown-menu" role="menu">
//                                     <div className="dropdown-content">
//                                         {dropdownList}
//                                     </div>
//                                 </div>
//                             </div>
//                             {selectEligibilityDisplay}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default EligbilityStatus;