import React, { useRef } from 'react';
// import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const Benefits = ({ blok }) => {
    // console.log(blok)
    const scollToRef_1 = useRef();
    const scollToRef_2 = useRef();

    return (
        <div {...sbEditable(blok)}>
            <section id="services" className="section is-medium bg-light-beige">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-two-thirds py-0">
                            <div className="content">
                                <h1 className="is-size-2 label resourcesTitle">{blok.title}</h1>
                                {render(blok.description)}
                            </div>
                        </div>
                        <div className="column is-one-third has-text-centered-mobile  py-0">
                            <img
                                className="service-image"
                                src={blok.page_image.filename}
                                alt={blok.page_image.alt}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Benefits;