import React, { useState } from "react";
import Helmet from "./Helmet"
import { Link } from "gatsby"
import Navigation from "./Navigation"
import { navigate } from "gatsby"
import Footer from './Footer'
import { useStaticQuery, graphql } from "gatsby"

export default function Layout({ children, location, lang }) {
  const { settings } = useStaticQuery(graphql`
  query Settings {
    settings: allStoryblokEntry(filter: {field_component: {eq: "Navigation"}}) {
      edges {
        node {
          name
          full_slug
          content
          lang
        }
      }
    }
  } 
  `)
  // console.log(settings)
  let { pathname } = location
  console.log(pathname)
  let language = pathname.split("/")[1].replace('/', '')
  // console.log(language)
  let activeLanguage = ['es', 'zh', 'vi', 'ko', 'km', 'hy', 'ru', 'tl', 'th', 'pa', 'ur', 'hi', 'fa', 'ar'].includes(language) ? language : 'default'
  let rtl = ['ur', 'fa', 'ar'].includes(language) ? "rtl" : 'ltr'
  let correctSetting = settings.edges.filter(edge => edge.node.lang === activeLanguage)
  let hasSetting = correctSetting && correctSetting.length ? correctSetting[0].node : {}
  let content = typeof hasSetting.content === 'string' ? JSON.parse(hasSetting.content) : hasSetting.content
  console.log(content)
  let parsedSetting = Object.assign({}, content, { content: content })

  const [langState, setLangState] = useState(lang)

  const langlist = [
    { lang: "default", name: "ENGLISH" },
    { lang: "es", name: "ESPAÑOL" },
    { lang: "zh", name: "中文" },
    { lang: "vi", name: "TIẾNG VIỆT" },
    { lang: "ko", name: "한국어" },
    { lang: "km", name: "ខ្មែរ" },
    { lang: "hy", name: "հայերեն" },
    { lang: "ru", name: "русский" },
    { lang: "tl", name: "TAGALOG" },
    { lang: "th", name: "ไทย" },
    { lang: "hi", name: "हिन्दी" },
    { lang: "pa", name: "ਪੰਜਾਬੀ" },
    { lang: "ar", name: "اردو" },
    { lang: "fa", name: "فارسی" },
    { lang: "ur", name: "عربي" }
  ]

  const setLanguageSelect = (lang) => {
    const selectedLang = lang
    const pagePathHome = pathname
    const pagePath = pagePathHome.length == 3 ? "" : pagePathHome
    // console.log(rewriteSlug(pagePath, selectedLang))
    // console.log(pagePath.substring(3, 4))
    if (pagePath.substring(3, 4) == "/" && selectedLang != "default" && pagePath.length != 3) {
      const result = "/" + selectedLang + pagePath.substring(3)
      return result
    } else if (selectedLang != "default") {
      const result = "/" + selectedLang + pagePath
      return result
    }

    if (selectedLang == "default" && pagePath.substring(3, 4) != "/" && pagePath.length != 0) {
      const result = pagePath
      // console.log(result)
      return result
    } else if(pagePath.length != 0){
      const result = pagePath.substring(3)
      // console.log(result)
      return result
    } else {
      const result = "/"
      return result
    }

  }

  const coverLanguageLinks = langlist.map((lang, index) =>
    <React.Fragment key={index}>
      <Link
        className="is-underlined is-size-6"
        to={setLanguageSelect(lang.lang)}
        style={{ textUnderlineOffset: ".15em" }}
        key={index}
      >
        {lang.name}
      </Link>
      {index != (langlist.length - 1) ? " | " : ""}
    </React.Fragment>
  )

  return (
    <div className="has-navbar-fixed-top">
      <Helmet lang={rtl} />
      <Navigation settings={parsedSetting} lang={activeLanguage} />
      <div className="language-bar is-fullwidth is-hidden-mobile">
        <div className="container"
          style={{ backgroundColor: "#ffe08a", padding: "5px" }}
        >{coverLanguageLinks}</div>
      </div>
      {React.cloneElement(children, { lang: activeLanguage })}
      <Footer settings={parsedSetting} lang={activeLanguage} />
    </div>
  )
}