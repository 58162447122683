import React from 'react';
import { Link } from "gatsby"
import facebook_icon from '../images/facebook.svg'
import instagram_icon from '../images/instagram.svg'
import twitter_icon from '../images/twitter.svg'
import { render } from "storyblok-rich-text-react-renderer"
import rewriteSlug from '../utils/rewriteSlug'
import { sbEditable } from "@storyblok/storyblok-editable";

const Footer = ({ settings, lang }) => {
  // console.log(settings)

  const footerItems = settings.Menu.map((menuItem, index) =>
    <React.Fragment key={index}>
      {
        menuItem.AddToNavbar ?
          <p className="is-size-5 is-size-5-mobile has-text-right grey-link" key={index}>
            <Link
              to={`/${rewriteSlug(menuItem.Link.cached_url, lang)}`}
              prefetch="true"
              key={index}
            >
              {menuItem.Item}
            </Link>
          </p>
          : <></>
      }
    </React.Fragment>
  )

  return <section
    className="section footer" {...sbEditable(settings)}
    style={{ backgroundColor: `#B6DBD7` }}
  >
    <div className="container">
      <div className="columns">
        <div className="column is-two-thirds">
          <p className="label is-size-6 is-size-6-mobile">
            {settings.copyright}
          </p>
          <p className="">
            {/* <a href="https://www.facebook.com/bailanetwork" target="_blank" className="is-size-6" without="true" rel="noreferrer">
              <img src={facebook_icon} alt="facebook" style={{ height: "45px" }} />
            </a> */}
            <a href="https://www.instagram.com/bailanetwork/" target="_blank" className="is-size-6" without="true" rel="noreferrer">
              <img src={instagram_icon} alt="instagram" style={{ height: "45px" }} />
            </a>
            <a href="https://twitter.com/bailanetwork" target="_blank" className="is-size-6" without="true" rel="noreferrer">
              <img src={twitter_icon} alt="twitter" style={{ height: "45px" }} />
            </a>
          </p>
          <div className="content">
            {render(settings.privacy)}
          </div>
        </div>
        <div className="column is-one-third">
          {footerItems}
        </div>
      </div>
    </div>
  </section>
};

export default Footer;
